// ---------- Web ----------

const localWebUrl = 'http://localhost:3000';
const liveWebUrl = 'https://www.navkarnaturally.in';


// ---------- Server ----------

const localApiUrl = 'http://localhost:4000';
const liveApiUrl = 'https://navkar-server.onrender.com';


// ---------- Images ----------

const localImgUrl = '/images';
const liveImgUrl = 'https://www.navkarnaturally.in/images';



// ---------- Check ----------

let checkWebUrl, checkApiUrl, checkImgUrl;

if (window.location.href.includes(localWebUrl)) {
  checkWebUrl = localWebUrl;
  checkApiUrl = localApiUrl;
  checkImgUrl = localImgUrl;
} else {
  checkWebUrl = liveWebUrl;
  checkApiUrl = liveApiUrl;
  checkImgUrl = liveImgUrl;
};


// ---------- Final ----------

const BaseWebUrl = checkWebUrl;
const BaseApiUrl = checkApiUrl;
const BaseImgUrl = checkImgUrl;



export { BaseWebUrl, BaseApiUrl, BaseImgUrl };