import { BaseImgUrl } from "./Config";

export const related = [
    {
        image: `${BaseImgUrl}/aachar-2.png`,
        name: 'Aam Ka Aachar',
        rating: 3.5,
        description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
        size1: '100 gm',
        size2: '200 gm',
        price1: '120',
        price2: '150',
    },
    {
        image: `${BaseImgUrl}/aachar-2.png`,
        name: 'Jam Ka Aachar',
        rating: 4,
        description: 'Nimbu Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
        size1: '150 gm',
        size2: '250 gm',
        price1: '150',
        price2: '200',
    },
    {
        image: `${BaseImgUrl}/aachar-3.png`,
        name: 'Wam Ka Aachar',
        rating: 2.5,
        description: 'Aawla Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
        size1: '175 gm',
        size2: '225 gm',
        price1: '175',
        price2: '250',
    },
    {
        image: `${BaseImgUrl}/aachar-2.png`,
        name: 'Aam Ka Aachar',
        rating: 3.5,
        description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
        size1: '100 gm',
        size2: '200 gm',
        price1: '120',
        price2: '150',
    },
    {
        image: `${BaseImgUrl}/aachar-4.png`,
        name: 'Kam Ka Aachar',
        rating: 4.5,
        description: 'Aam Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
        size1: '100 gm',
        size2: '200 gm',
        price1: '120',
        price2: '150',
    },
    {
        image: `${BaseImgUrl}/aachar-5.png`,
        name: 'Nam Ka Aachar',
        rating: 3,
        description: 'Nimbu Ka Aachar is a popular Indian dish consisting of potatoes and a variety of other vegetables cooked in a spicy tomato-based sauce.',
        size1: '150 gm',
        size2: '250 gm',
        price1: '150',
        price2: '200',
    },
];



export const breakpoints = {
    320: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    576: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    768: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    992: {
        slidesPerView: 4,
        spaceBetween: 20,
    },
    1200: {
        slidesPerView: 4,
        spaceBetween: 20,
    },
    1400: {
        slidesPerView: 5,
        spaceBetween: 20,
    },
}

export const reviewbreakpoints = {
    0: {
        slidesPerView: 1,
        spaceBetween: 20,
    },
    // 320: {
    //     slidesPerView: 1,
    //     spaceBetween: 20,
    // },
    576: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    // 768: {
    //     slidesPerView: 2,
    //     spaceBetween: 20,
    // },
    992: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    // 1200: {
    //     slidesPerView: 3,
    //     spaceBetween: 20,
    // },
    1400: {
        slidesPerView: 4,
        spaceBetween: 20,
    },
}


// export const homeCategories = [
//     {
//         name: "Achar",
//         img: "/images/categories/food1.png",
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
//         items: "20",
//         link: ""
//     },
//     {
//         name: "Papad",
//         img: "/images/categories/food2.webp",
//         // img: "https://i0.wp.com/srivarahafoods.com/wp-content/uploads/2024/04/SriVarahaFoods-Kallidaikurichi-Ulunthu-Appalam-Urad-dal-Papad-%E0%AE%89%E0%AE%B3%E0%AF%81%E0%AE%A8%E0%AF%8D%E0%AE%A4%E0%AF%81-%E0%AE%85%E0%AE%AA%E0%AF%8D%E0%AE%AA%E0%AE%B3%E0%AE%AE%E0%AF%8D-300g.jpg?resize=300%2C300&ssl=1",
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
//         items: "20",
//         link: ""
//     },
//     {
//         name: "Sharbat",
//         img: "/images/categories/food3.png",
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
//         items: "20",
//         link: ""
//     },
//     {
//         name: "Laddu",
//         img: "/images/categories/food4.png",
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
//         items: "20",
//         link: ""
//     },
//     {
//         name: "food1",
//         img: "/images/categories/food1.png",
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
//         items: "20",
//         link: ""
//     },
// ]

export const featuredItems = [
    {
        img: "/images/featured-items/cate1.png",
        name: "Aam Achar",
        starCount: 5,
        quantities: ["200gm", "500gm"],
        sp: "₹240.00",
        cp: "₹90.00",
        link: "",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage."
    },
    {
        img: "/images/featured-items/cate2.png",
        name: "Udad Pappad",
        starCount: 4,
        quantities: ["200gm", "500gm"],
        sp: "₹140.00",
        cp: "₹80.00",
        link: "",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage."
    },
    {
        img: "/images/featured-items/cate3.png",
        name: "Masala Soda",
        starCount: 3,
        quantities: ["100gm", "250gm"],
        sp: "₹220.00",
        cp: "₹120.00",
        link: "",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage."
    },
    {
        img: "/images/featured-items/cate4.png",
        name: "Lal Mirch Aachar",
        starCount: 5,
        quantities: ["100gm", "300gm"],
        sp: "₹240.00",
        cp: "₹90.00",
        link: "",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage."
    },
    {
        img: "/images/featured-items/cate1.png",
        name: "Aam Achar",
        starCount: 4,
        quantities: ["100gm", "400gm"],
        sp: "₹240.00",
        cp: "₹90.00",
        link: "",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage."
    },
    {
        img: "/images/featured-items/cate4.png",
        name: "Lal Mirch Aachar",
        starCount: 5,
        quantities: ["100gm", "300gm"],
        sp: "₹240.00",
        cp: "₹90.00",
        link: "",
        description: " It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passage."
    },
]

export const reviews = [
    {
        name: "Karan Sahu",
        img: "/images/customer1.jpg",
        review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi voluptates delectus unde! Quas, esse porro minima est voluptatibus blanditiis aperiam?",
        startCount: 5

    },
    {
        name: "Kunam Yadav",
        img: "/images/customer2.jpg",
        review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi voluptates delectus unde! Quas, esse porro minima est voluptatibus blanditiis aperiam?",
        startCount: 4

    }, {
        name: "Vaibhav sinha",
        img: "/images/customer3.jpg",
        review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi voluptates delectus unde! Quas, esse porro minima est voluptatibus blanditiis aperiam?",
        startCount: 3

    }, {
        name: "Karan Sahu",
        img: "/images/customer1.jpg",
        review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi voluptates delectus unde! Quas, esse porro minima est voluptatibus blanditiis aperiam?",
        startCount: 5

    }, {
        name: "Karan Sahu",
        img: "/images/customer1.jpg",
        review: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Excepturi voluptates delectus unde! Quas, esse porro minima est voluptatibus blanditiis aperiam?",
        startCount: 5

    },
]