import React, { useContext } from 'react'
import { BaseImgUrl } from '../../Config'
import { UserContext } from '../UseContext/NavkarContext'



const Contact = () => {

  const { contact, setContact, handleContactSubmit } = useContext(UserContext);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContact(preState => ({
      ...preState,
      [name]: value
    }));
  };

  return (
    <>
      <section>
        {/* banner */}
        <div className='about_banner'>
          <h1 className='text-warning'>NAVKAR</h1>
          <p>Naturally Home Made</p>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>

        {/* Adddress details */}
        <div className="position-relative border border-1">
          <div className='about_cards_position w-100'>
            <div className='d-flex justify-content-center align-items-center flex-column flex-md-row gap-5 '>
              <div className='about_card'>
                <div className='rounded mb-3'>
                  <img width={50} className='about_card_img' src={`${BaseImgUrl}/icons/calling.svg`} alt="" />
                </div>
                <p>+91 9201623579</p>
              </div>

              <div className='about_card'>
                <div className='rounded mb-3'>
                  <img width={50} className='about_card_img' src={`${BaseImgUrl}/icons/email.svg`} alt="" />
                </div>
                <p>navkarnaturally@gmail.com</p>
              </div>

              <div className='about_card'>
                <div className='flex gap-2'>
                  <img width={30} src={`${BaseImgUrl}/icons/facebook.svg`} alt="facebook" />
                  <img width={30} src={`${BaseImgUrl}/icons/linkedin.svg`} alt="linkedin" />
                  <img width={30} src={`${BaseImgUrl}/icons/instagram.svg`} alt="instagram" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stickers */}
        <div className='d-none d-lg-block  position-relative' style={{ marginTop: "100px" }}>
          <img width={250} className='d-none d-lg-block sticker_1' src='/images/sticker3.png' alt='' style={{ top: "-80px" }} />

          <img width={250} className='d-none d-lg-block sticker_2' src='/images/sticker4.png' alt='' />
        </div>

        {/* form */}
        <div className='py-5'>
          <h3 className='text-center fw-bold'>Send Us Message</h3>
          <div className='container py-5 d-flex justify-content-center bg-white shadow col-10 col-md-8 rounded mt-4'>
            <form action="" className='row'>
              <div className='row col-12 justify-content-center'>
                <div className="col-12 col-md-5 mb-3">
                  <label htmlFor="fname">First name</label>
                  <input type="text" className="form-control" id="fname" name='fname' value={contact.fname} onChange={handleChange} placeholder="First name..." required />
                </div>

                <div className="col-12 col-md-5 mb-3">
                  <label htmlFor="lname">Last name</label>
                  <input type="text" className="form-control" id="lname" name='lname' value={contact.lname} onChange={handleChange} placeholder="Last name..." required />
                </div>
              </div>

              <div className='row col-12 justify-content-center'>
                <div className="col-12 col-md-5 mb-3">
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" id="email" name='email' value={contact.email} onChange={handleChange} placeholder="Enter Email..." required />
                </div>

                <div className="col-12 col-md-5 mb-3">
                  <label htmlFor="number">Number</label>
                  <input type="text" className="form-control" id="number" name='number' value={contact.number} onChange={handleChange} placeholder="Mobile Number..." required />
                </div>
              </div>

              <div className="col-12 col-md-10 mb-3 m-auto">
                <label htmlFor="message">Message</label>
                <textarea type="text" rows={6} className="form-control mt-2" id="message" name='message' value={contact.message} onChange={handleChange} placeholder="Type Your Message..." required />
              </div>
              
              <div className='row justify-content-center'>
                <button type="button" className="col-6 col-md-2 btn btn-success rounded-5" onClick={handleContactSubmit}>Send</button>
              </div>
            </form>
          </div>
        </div>

        {/* location */}
        <div className='row justify-content-center my-3 col-10 col-lg-8 mx-auto '>
          <h3 className='text-center my-3'>Location</h3>
          <iframe className='my-3 rounded shadow p-0' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7437.910133033711!2d81.62815745283172!3d21.233630325212967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddbbee177c37%3A0x145e8e5560de086f!2sBudhapara%2C%20Raipur%2C%20Chhattisgarh%20492001!5e0!3m2!1sen!2sin!4v1718783533525!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Location of Federation Square on Google Maps"></iframe>
        </div>
      </section >
    </>
  )
}

export default Contact
