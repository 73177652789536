import React, { useContext, useEffect } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import { BaseImgUrl } from '../../Config';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UseContext/NavkarContext';



const Categories = () => {

  const navigate = useNavigate();

  const { 
    categories, getCategories, 
  } = useContext(UserContext);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <section>
        {/* Ads */}
        <Swiper
          className="mySwiper categories-banner"
          loop={true}
          modules={[Navigation, Pagination, Keyboard]}
          navigation={true}
          pagination={{
            dynamicBullets: true,
          }}
          keyboard={true}
          slidesPerView={1}
        >
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/s2.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/s1.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/banr3.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/blog/blog_1.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
          <SwiperSlide><img src={`https://dostguru.com/HTML/flavoro/assets/img/blog/blog_3.jpg`} style={{ height: '100%', width: '100%' }} alt="check" /></SwiperSlide>
        </Swiper>

        <div className='my-3'>
          {/* Header */}
          <div className='d-flex flex-column align-items-center my-4 my-md-5'>
            <h3 className='home-head-1 mb-0'>Categories</h3>
            <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
            <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
          </div>

          {/* Categories */}
          <div className='products position-relative'>
            {
              categories && categories.length > 0
              &&
              categories.map((category, index) => (
                <div key={index} className='products-product position-relative shadow rounded-4' onClick={() => navigate(`/products/${category._id}`)}>
                  <img className='shadow cate_img rounded-5 w-100' src={category.image} alt="check" />
                  
                  <p className='flex img_last card_width_1'>
                    <span className='text-warning'>{category.name}</span>
                    <span>{category.products}</span>
                  </p>
                  
                  <div className='img_details card_width_2'>
                    <div className='flex mb-2'>
                      <div className=''>
                        <p className='text-warning '>{category.name}</p>
                        <p className='text-white'>{category.products}</p>
                      </div>
                      <button className='see_all_btn'>See All</button>
                    </div>
                    <p className='text-white my-2 fs-10'>{category.description}</p>
                  </div>
                  {/* <img src={image} className='products-product-img w-100 rounded-4 h-100 w-100 object-fit-cover' alt="categories" /> */}
                </div>
              ))
            }
          </div>

        </div>

      </section>
    </>
  )
}

export default Categories
