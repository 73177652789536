import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BaseImgUrl } from '../../Config';
import Rating from 'react-rating';
// import OtpInput from "react-otp-input";
import SelectAvtarPopup from './SelectAvtarPopup';
import { UserContext } from '../UseContext/NavkarContext';



const CustomerReviewPopup = ({ showCustomerReviewPopup, setShowCustomerReviewPopup }) => {

  const { 
    customerReview, setCustomerReview, 
    showCustomerReviewAvtarPopup, setShowCustomerReviewAvtarPopup,
    handleChangeCustomerReview,
    handleCustomerReviewRating,
    handleCustomerReviewSubmit,
  } = useContext(UserContext);


  return (
    <>
      {/* Avtar Popup */}
      <SelectAvtarPopup showAvtarPopup={showCustomerReviewAvtarPopup} setShowAvtarPopup={setShowCustomerReviewAvtarPopup} setShowCustomerReviewPopup={setShowCustomerReviewPopup} setCustomerReview={setCustomerReview} />

      <Modal show={showCustomerReviewPopup} onHide={() => setShowCustomerReviewPopup(false)} centered keyboard={false} className="" >
        <Modal.Header closeButton className='border-bottom-0'>
          <h4 className='mx-auto'>Customer Review</h4>
        </Modal.Header>

        <Modal.Body className='overflow-y-scroll scrollbar-hidden' style={{ maxHeight: '75vh' }}>
          <section className='d-flex flex-column'>
            {/* Profile */}
            <div className='text-center mb-4 position-relative'>
              {
                customerReview.profile
                ?
                <img src={customerReview.profile} className='rounded-circle shadow object-fit-cover' style={{ height: '125px', width: '125px' }} alt="profile" />
                :
                <img src={`${BaseImgUrl}/avtar/dummy-avtar.png`} className='rounded-circle shadow object-fit-cover' style={{ height: '125px', width: '125px' }} alt="profile" />
              }

              <img src={`${BaseImgUrl}/icons/camera.svg`} onClick={() => {setShowCustomerReviewAvtarPopup(true); setShowCustomerReviewPopup(false)}} className='border bg-secondary-subtle p-1 rounded-circle position-absolute top-100 start-50 translate-middle' style={{ width: '30px' }} alt="camera" />
            </div>

            {/* Rating */}
            <Rating
              className='text-center mt-3 mb-3'
              initialRating={customerReview.rating}
              onChange={handleCustomerReviewRating}
              emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '23px' }} alt="empty" />}
              fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '23px' }} alt="fill" />}
            />

            {/* Name */}
            <div className="form-floating mb-3">
              <input type="text" className="form-control" value={customerReview.name} onChange={handleChangeCustomerReview} name='name' placeholder="Name..." />
              <label htmlFor="floatingInput">Name</label>
            </div>

            {/* Review */}
            <div className="form-floating mb-3">
              <textarea className="form-control" value={customerReview.review} onChange={handleChangeCustomerReview} name='review' placeholder="Leave a comment here" style={{ height: '100px' }}></textarea>
              <label htmlFor="floatingTextarea">Write your review here...</label>
            </div>

            {/* Number */}
            <div className="form-floating mb-3">
              <input type="tel" className="form-control" value={customerReview.number} onChange={handleChangeCustomerReview} name='number' pattern='[0-9]{10}' placeholder="Name..." />
              <label htmlFor="floatingInput">Number</label>
            </div>

            {/* Button */}
            <div className='text-center'>
              <button type="button" className='btn btn-primary w-50' onClick={handleCustomerReviewSubmit}>Submit</button>
            </div>
          </section> 
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomerReviewPopup;