import React, { useContext } from 'react'
import { BaseImgUrl } from '../../Config'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { breakpoints, related } from '../../JsonData';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Rating from 'react-rating';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UseContext/NavkarContext';



const FeaturedItems = () => {

  const navigate = useNavigate();

  const { navkar } = useContext(UserContext);
 
  return (
    <section className='mb-5'>
      <section className='mt-5 mb-3'>
        <div className='d-flex flex-column align-items-center'>
          <h3 className='home-head-1 mb-0'>Featured Items</h3>
          <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>
      </section>

      <div className='position-relative'>
        <img width={250} className='d-none d-lg-block sticker_1' src='/images/sticker1.png' alt='' />
      
        <img width={250} className='d-none d-lg-block sticker_2' src='/images/sticker2.png' alt='' />
      </div>

      <div className='feature_tabs mt-3'>
        <Tabs defaultActiveKey="new_seller" transition={true} id="noanim-tab-example" className="my-3 my-lg-5" >
          {/* New Arrival */}
          <Tab eventKey="new_seller" title="New Arrival">
            <div className='container px-0'>
              <Swiper
                className="mySwiper col-11 mx-0 mt-4 home_featured w-100 px-2"
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                slidesPerView={2}
                spaceBetween={20}
                breakpoints={breakpoints}
                modules={[Pagination]}
              >
                {related.map((product, index) => (
                  <SwiperSlide key={index} onClick={() => navigate(`product`)}>
                    <div className='bg-white rounded-4 p-2 shadow-sm '>
                      <img src={product.image} className='w-100' alt="aachar" />

                      <div className='fs-6 fw-semibold'>{product.name}</div>

                      {/* Rating */}
                      <Rating
                        className=''
                        initialRating={product.rating}
                        readonly
                        emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '13px' }} alt="empty" />}
                        fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                      />

                      <small className='d-block my-1'>Lorem ipsum dolor sit amet, consectetur elit.</small>

                      {/* Size */}
                      <div>
                        <span className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size1}</span>
                      </div>

                      {/* Price */}
                      <div className='my-2'>
                        <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹120.00</span>
                        <span className='text-decoration-line-through text-secondary'>₹150.00</span>
                      </div>

                      {/* Order or Call */}
                      <div className='d-flex justify-content-evenly mb-2'>
                        {/* Order */}
                        <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2' 
                          onClick={(e) => { 
                            e.stopPropagation(); 
                            window.location.href = `https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`;
                          }}
                        >
                          <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                          <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                        </div>

                        {/* Call */}
                        <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2' 
                          onClick={(e) => { 
                            e.stopPropagation(); 
                            window.location.href = `tel:${navkar.number}`;
                          }}
                        >
                          <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                          <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                        </div>
                      </div>
                    </div>

                    {/* Avi ka code hai */}
                    {/* <div className='shadow bg-white rounded-3'>

                      <img src={e.img} alt={e.name} className='w-100 px-1 px-1 px-lg-5 py-lg-2' />
                      <div className='px-2'>
                        <p className='fw-bold mt-2'>{e.name}</p>
                        <div style={{ lineHeight: "1" }}>
                          {Array(e.starCount).fill(0).map((start, s) => (
                            <img key={s} width={10} src="/images/icons/star.png" alt="" />
                          ))}
                          {Array(5 - e.starCount).fill(0).map((start, s) => (
                            <img key={s} width={10} src="/images/icons/blank_star.png" alt="" />
                          ))}
                        </div>
                        <p className='d-none d-md-block text-justify mt-2 fs-10'>{e.description}</p>
                        <div className='d-flex gap-2 mt-1'>
                          <span className='cate_w1'>{e.quantities[0]}</span>
                          <span className='cate_w2'>{e.quantities[1]}</span>
                        </div>
                        <div className='mt-2'>
                          <span><strong>{e.cp}</strong></span>
                          <span style={{ fontSize: "12px", color: "#d3d3d3" }} className='ms-2 fw-bold'><del>{e.sp}</del></span>
                        </div>
                        <button className='my-2 order-btn'>
                          <img className='me-2' src="/images/icons/wp.svg" width={12} alt="" />
                          order
                        </button>
                      </div>
                    </div> */}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Tab>

          {/* Best Seller */}
          <Tab eventKey="Best_Seller" title="Best Seller">
            <div className='container px-0'>
              <Swiper
                className="mySwiper col-11 mx-0 mt-4 home_featured w-100 px-2"
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                slidesPerView={1}
                spaceBetween={20}
                breakpoints={breakpoints}
                modules={[Pagination]}
              >
                {related.map((product, index) => (
                  <SwiperSlide key={index} onClick={() => navigate(`product`)}>
                    <div className='bg-white rounded-4 p-2 shadow-sm '>
                      <img src={product.image} className='w-100' alt="aachar" />

                      <div className='fs-6 fw-semibold'>{product.name}</div>

                      {/* Rating */}
                      <Rating
                        className=''
                        initialRating={product.rating}
                        readonly
                        emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '13px' }} alt="empty" />}
                        fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                      />

                      <small className='d-block my-1'>Lorem ipsum dolor sit amet, consectetur elit.</small>

                      {/* Size */}
                      <div>
                        <span className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size1}</span>
                      </div>

                      {/* Price */}
                      <div className='my-2'>
                        <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹120.00</span>
                        <span className='text-decoration-line-through text-secondary'>₹150.00</span>
                      </div>

                      {/* Order or Call */}
                      <div className='d-flex justify-content-evenly mb-2'>
                        {/* Order */}
                        <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2'
                          onClick={(e) => { 
                            e.stopPropagation(); 
                            window.location.href = `https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`;
                          }}
                        >
                          <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                          <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                        </div>

                        {/* Call */}
                        <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2'
                          onClick={(e) => { 
                            e.stopPropagation(); 
                            window.location.href = `tel:${navkar.number}`;
                          }}
                        >
                          <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                          <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                        </div>
                      </div>
                    </div>

                    {/* Avi ka code hai */}
                    {/* <div className='shadow bg-white rounded-3'>
                      <img src={e.img} alt={e.name} className='w-100 px-1 px-1 px-lg-5 py-lg-2' />
                      <div className='px-2'>
                        <p className='fw-bold mt-2'>{e.name}</p>
                        <div style={{ lineHeight: "1" }}>
                          {Array(e.starCount).fill(0).map((start, s) => (
                            <img key={s} width={10} src="/images/icons/star.png" alt="" />
                          ))}
                          {Array(5 - e.starCount).fill(0).map((start, s) => (
                            <img key={s} width={10} src="/images/icons/blank_star.png" alt="" />
                          ))}
                        </div>
                        <p className='d-none d-md-block text-justify mt-2 fs-10' >{e.description}</p>
                        <div key={i} className='d-flex gap-2'>
                          <span className='cate_w1'>{e.quantities[0]}</span>
                          <span className='cate_w2'>{e.quantities[1]}</span>
                        </div>
                        <div className='mt-2'>
                          <span><strong>{e.cp}</strong></span>
                          <span style={{ fontSize: "12px", color: "#d3d3d3" }} className='ms-2 fw-bold'><del>{e.sp}</del></span>
                        </div>
                        <button className='my-2 order-btn'>
                          <img className='me-2' src="/images/icons/wp.svg" width={12} alt="" />
                          order
                        </button>
                      </div>
                    </div> */}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Tab>
        </Tabs>
      </div>
    </section>
  )
}

export default FeaturedItems
