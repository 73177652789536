import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BaseImgUrl } from '../../Config';
import { UserContext } from '../UseContext/NavkarContext';


const SelectAvtarPopup = () => {

  const { 
    showAvtarPopup, setShowAvtarPopup, 
    setShowCustomerReviewPopup, 
    setShowProductReviewPopup, 
    setProductReview, 
  } = useContext(UserContext);

  const avtars = [
    `${BaseImgUrl}/avtar/avtar-1.jpg`,
    `${BaseImgUrl}/avtar/avtar-2.jpg`,
    `${BaseImgUrl}/avtar/avtar-3.jpg`,
    `${BaseImgUrl}/avtar/avtar-1.jpg`,
    `${BaseImgUrl}/avtar/avtar-2.jpg`,
    `${BaseImgUrl}/avtar/avtar-3.jpg`,
    `${BaseImgUrl}/avtar/avtar-1.jpg`,
    `${BaseImgUrl}/avtar/avtar-2.jpg`,
    `${BaseImgUrl}/avtar/avtar-3.jpg`,
    `${BaseImgUrl}/avtar/avtar-1.jpg`,
    `${BaseImgUrl}/avtar/avtar-2.jpg`,
    `${BaseImgUrl}/avtar/avtar-3.jpg`,
  ];

  // To handle profile
  const handleProfile = (e) => {
    const selected = e.target.src;

    // console.log('selected : ', selected);

    setProductReview(preState => ({
      ...preState,
      profile: e.target.src
    }));

    setShowAvtarPopup(false);

    setShowCustomerReviewPopup && setShowCustomerReviewPopup(true);
    setShowProductReviewPopup && setShowProductReviewPopup(true);
  };

  return (
    <>
      <Modal show={showAvtarPopup} onHide={() => setShowAvtarPopup(false)} centered keyboard={false} className="" >
        <Modal.Header closeButton className='border-bottom-0'>
          <h4 className='mx-auto'>Select Avtar</h4>
        </Modal.Header>

        <Modal.Body className='overflow-y-scroll' style={{ maxHeight: '50vh' }}>
          <section className='d-flex flex-wrap justify-content-evenly'>
            {
              avtars.map((avtar, index) => (
                <img src={avtar} className='rounded-circle shadow mb-3' onClick={handleProfile} alt="avtar" style={{ width: '40%' }} key={index} />
              ))
            }
          </section>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SelectAvtarPopup
