import React, { useContext } from 'react'
import { BaseImgUrl } from '../../Config'
import { breakpoints, related } from '../../JsonData'
import { Link, useNavigate } from 'react-router-dom'
import Rating from 'react-rating'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { UserContext } from '../UseContext/NavkarContext'


const HomeProducts = () => {

  const navigate = useNavigate();

  const { navkar } = useContext(UserContext);

  return (
    <div className='mt-5'>
      <section className='my-3'>
        <div className='d-flex flex-column align-items-center'>
          <h3 className='home-head-1 mb-0'>Products</h3>
          <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>

        <div className='position-relative'>
          <img width={250} className='d-none d-lg-block sticker_1' src='/images/sticker3.png' alt='' style={{ top: "-80px" }} />

          <img width={250} className='d-none d-lg-block sticker_2' src='/images/sticker4.png' alt='' />
        </div>

        <div className='container home_categories mt-4 mt-lg-5 px-0'>
          <Swiper
            className="mySwiper col-11 mx-0 mt-3 w-100 p-2"
            pagination={{
              dynamicBullets: true,
            }}
            slidesPerView={1}
            spaceBetween={20}
            breakpoints={breakpoints}
            modules={[Pagination]}
          >
            {related.map((product, index) => (
              <SwiperSlide key={index} onClick={() => navigate(`product`)}>
                <div className='bg-white rounded-4 p-2 shadow-sm '>
                  <img src={product.image} className='w-100' alt="aachar" />

                  <div className='fs-6 fw-semibold'>{product.name}</div>

                  {/* Rating */}
                  <Rating
                    className=''
                    initialRating={product.rating}
                    readonly
                    emptySymbol={<img src={`${BaseImgUrl}/icons/rating-1.svg`} className='me-1' style={{ height: '13px' }} alt="empty" />}
                    fullSymbol={<img src={`${BaseImgUrl}/icons/rating-2.svg`} className='me-1' style={{ height: '13px' }} alt="fill" />}
                  />

                  <small className='d-block my-1'>Lorem ipsum dolor sit amet, consectetur elit.</small>

                  {/* Size */}
                  <div>
                    <span className='badge bg-warning text-white me-1 rounded-pill fw-normal'>{product.size1}</span>
                  </div>

                  {/* Price */}
                  <div className='my-2'>
                    <span className='fs-5 fw-semibold me-sm-3' style={{ marginRight: '3px' }}>₹120.00</span>
                    <span className='text-decoration-line-through text-secondary'>₹150.00</span>
                  </div>

                  {/* Order or Call */}
                  <div className='d-flex justify-content-evenly mb-2'>
                    {/* Order */}
                    <div className='border border-success rounded-pill badge d-flex align-items-center w-50 justify-content-center me-2 py-2'
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        window.location.href = `https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`;
                      }}
                    >
                      <img src={`${BaseImgUrl}/icons/product-order.svg`} alt="order" />
                      <span className='text-success fw-normal ms-1 ms-sm-2'>Order</span>
                    </div>

                    {/* Call */}
                    <div className='bg-success d-inline rounded-pill badge d-flex align-items-center w-50 justify-content-center py-2'
                      onClick={(e) => { 
                        e.stopPropagation(); 
                        window.location.href = `tel:${navkar.number}`;
                      }}
                    >
                      <img src={`${BaseImgUrl}/icons/product-call.svg`} alt="call" />
                      <span className='text-white fw-normal ms-1 ms-sm-2'>Call</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className="flex justify-content-center mb-5">
          <button className='pro_see_all fw-bold bg-transparent'>
            <Link to={"products"}>see all</Link>
          </button>
        </div>
      </section>
    </div >
  )
}

export default HomeProducts;