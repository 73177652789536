// import logo from './logo.svg';
import './App.css';
import './css/xs.css';
import './css/sm.css';
import './css/md.css';
import './css/lg.css';
import './css/xl.css';
import './css/xxl.css';

import RoutePage from './RoutePage.js';


const App = () => {
  return (
    <>
      <RoutePage />
    </>
  );
}

export default App;
