import React, { useState } from 'react';
import { BaseApiUrl } from '../../Config.js'
import axios from 'axios';
// import { UserContext } from '../UseContext/NavkarContext.js';

// ----- Swiper -----
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';



// ----- Slick -----

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const Check = () => {

  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
  });

  // console.log('user : ', user);

  // To handle change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser(preState => ({
      ...preState,
      [name]: value,
    }));
  };

  // To handle submit
  const handleSubmit = () => {
    console.log('submit : ', user);

    axios.post(`${BaseApiUrl}/user/checkUser`, user)
    .then(res => {
      console.log('res : ', res.data.message);

      alert(res.data.message);

      setUser({
        name: '',
        email: '',
        password: '',
      });
    })
    .catch(err => {
      console.log('err : ', err);
    });
  };

  // const { name } = useContext(UserContext);

  // console.log('name : ', name);

  // const images = [
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  //   `${BaseImgUrl}/aachar-1.png`,
  // ];


  // ----- Swiper -----

  const [thumbsSwiper, setThumbsSwiper] = useState(null);


  // ----- Slick -----

  const slickSettings = {
    dots: true,
    customPaging: function(i) {
      return (
        <div className='w-100'>
          <img src={`https://swiperjs.com/demos/images/nature-${i + 1}.jpg`} className='w-100' alt={`thumbnail-${i + 1}`} />
        </div>
      )
    },
    dotsClass: "slick-dots custom-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  

  return (
    <>
      <section className='bg-success-subtle'>
        <h1 className='text-center text-success'>Check</h1>

        <form className='d-flex mb-3'>
          <input type="text" className='form-control mx-2' name='name' value={user.name} onChange={handleChange} placeholder='Name...' />
          <input type="email" className='form-control mx-2' name='email' value={user.email} onChange={handleChange} placeholder='Email...' />
          <input type="password" className='form-control mx-2' name='password' value={user.password} onChange={handleChange} autoComplete='off' placeholder='Password...' />

          <button type="button" className='btn btn-primary mx-2' onClick={handleSubmit}>Submit</button>
        </form>


        {/* ----- Swiper ----- */}
        <h1 className='text-center'>Swiper</h1>

        <div className='border border-danger mx-auto mb-3' style={{ height: '300px', width: '90%' }}>
          <Swiper
            className="mySwiper2 h-75"
            style={{
              '--swiper-navigation-color': '#fff',
              '--swiper-pagination-color': '#fff',
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-1.jpg" className='w-100' alt='swiper' />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-2.jpg" className='w-100' alt='swiper' />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-3.jpg" className='w-100' alt='swiper' />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-4.jpg" className='w-100' alt='swiper' />
            </SwiperSlide>
          </Swiper>

          <Swiper
            className="mySwiper h-25"
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-1.jpg" className='cursor-pointer' alt='swiper' />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-2.jpg" className='cursor-pointer' alt='swiper' />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-3.jpg" className='cursor-pointer' alt='swiper' />
            </SwiperSlide>
            <SwiperSlide>
              <img src="https://swiperjs.com/demos/images/nature-4.jpg" className='cursor-pointer' alt='swiper' />
            </SwiperSlide>
          </Swiper>
        </div>


        {/* ----- Slick ----- */}
        <h1 className='text-center'>Slick</h1>

        <div className='border border-danger reactSlick mx-auto'>
          <Slider {...slickSettings}>
            <div className=''>
              <img src="https://swiperjs.com/demos/images/nature-1.jpg" className='h-100 w-100' alt="slide-1" />
            </div>
            <div className=''>
              <img src="https://swiperjs.com/demos/images/nature-2.jpg" className='h-100 w-100' alt="slide-2" />
            </div>
            <div className=''>
              <img src="https://swiperjs.com/demos/images/nature-3.jpg" className='h-100 w-100' alt="slide-3" />
            </div>
            <div className=''>
              <img src="https://swiperjs.com/demos/images/nature-4.jpg" className='h-100 w-100' alt="slide-4" />
            </div>
          </Slider>
        </div>




      

        {/* <img src={`${BaseImgUrl}/check.jpg`} className='h-25 w-25' alt="check" /> */}

        {/* Products */}
        {/* <div className='products mt-3'>
          {images.map(index => (
            <div key={index} className='border border-success rounded-4' style={{ height: '350px' }}>
            </div>
          ))}
        </div> */}
      </section>  
    </>
  )
}

export default Check;
