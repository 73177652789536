import React from 'react'

const Error = () => {
  return (
    <>
      <section>
        <h1 className='text-center text-danger'>Page Not Found</h1>
      </section>  
    </>
  )
}

export default Error
