import React, { useContext, useEffect } from 'react';
import { BaseImgUrl } from '../../Config';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import { breakpoints } from '../../JsonData';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UseContext/NavkarContext';



const HomeCategories = () => {

  const navigate = useNavigate();

  const { 
    categories, getCategories, 
  } = useContext(UserContext);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <section className='mt-5'>
        {/* Header */}
        <div className='d-flex flex-column align-items-center'>
          <h3 className='home-head-1 mb-0'>Categories</h3>
          <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>

        <div className='container home_categories mt-4 mt-lg-5 px-0'>
          <Swiper
            className="mySwiper col-11 mx-0 my-3 w-100 px-2"
            // pagination={{
            //   clickable: true,
            // }}
            pagination={true}
            slidesPerView={2}
            spaceBetween={20}
            breakpoints={breakpoints}
            modules={[Pagination]}
          >
            {
              categories && categories.length > 0 
              &&
              categories.map((category, index) => (
                <SwiperSlide key={index} className='position-relative rounded-5 shadow-sm' onClick={() => navigate(`/products/${category._id}`)}>
                  <img className='shadow cate_img rounded-5 w-100' src={category.image} alt="check" />
                  
                  <p className='flex img_last'>
                    <span className='text-warning fw-semibold ms-1 ms-md-2 mb-md-1'>{category.name}</span>
                    <span className='me-1 me-md-1 mb-md-1'>{category.products}</span>
                  </p>

                  <div className='img_details'>
                    <div className='flex mb-2'>
                      <div className='img_last'>
                        <p className='text-warning'>{category.name}</p>
                        <p className='text-white'>{category.products}</p>
                      </div>
                      <button className='see_all_btn'>See All</button>
                    </div>
                    <p className='text-white my-2 fs-10'>{category.description}</p>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </section>
    </>
  )
}

export default HomeCategories;