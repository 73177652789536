import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BaseImgUrl } from '../../Config'
import { UserContext } from '../UseContext/NavkarContext.js';



const Header = () => {

  const [isScrolled, setIsScrolled] = useState(false);

  const urlPath = window.location.pathname;

  const navigate = useNavigate();

  const { handleClickOffCanvas, navkar } = useContext(UserContext);

  // To give blur effect to background of header on scroll
  document.body.onscroll = () => {
    // console.log('scroll : ', window.scrollY);

    if(window.scrollY >= 1) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    };
  };

  return (
    <>
      {/* Mobile Header */}
      <section className={`header navbar ${isScrolled && `header-blur shadow-sm`} fixed-top d-md-none`}>
        <div className="container-fluid">
          {/* Offcanvas Header */}
          <>
            {/* Logo */}
            <img src={`${BaseImgUrl}/logo.png`} className="ms-3 headerLogo" onClick={() => navigate(`/`)} alt="logo" />

            {/* Offcanvas Button */}
            <button className="navbar-toggler border border-light p-1 pt-0 me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" style={{ height: '20px', width: '20px', filter: 'invert(1)' }}></span>
            </button>
          </>

          {/* Offcanvas Body */}
          <div className="offcanvas offcanvas-start bg-dark vh-100" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <img src={`${BaseImgUrl}/logo.png`} className="offcanvas-logo offcanvas-title ms-1" onClick={() => handleClickOffCanvas(`/`)} alt="logo" id="offcanvasNavbarLabel" />

              <button type="button" className="btn-close btn-close-white me-1" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div className="offcanvas-body">
              {/* Search */}
              <div className='border border-white text-white mb-5 d-flex align-items-center rounded-pill bg-light'>
                <img src={`${BaseImgUrl}/icons/search-black.svg`} className='ms-3 me-2' style={{ height: '20px' }} alt="search" />
                <input type="search" className='form-control rounded-pill bg-transparent border-0 shadow-none' placeholder='Search...' />
              </div>

              {/* Home */}
              <div 
                className={`${urlPath === '/' && `rounded-2 bg-warning`} p-2 mb-3 d-flex align-items-center text-decoration-none text-white`}
                onClick={() => handleClickOffCanvas(`/`)} 
              >
                <img src={urlPath === '/' ? `${BaseImgUrl}/icons/footer-home.svg` : `${BaseImgUrl}/icons/footer-home.svg`} className='offCanvas-img ms-2 me-3' style={{ height: '25px' }} alt="dashboard" />
                <h5 className='mb-0'>Home</h5>
              </div>

              {/* Categories */}
              <div 
                className={`${urlPath === '/categories' && `rounded-2 bg-warning`} p-2 mb-3 d-flex align-items-center text-decoration-none text-white`}
                onClick={() => handleClickOffCanvas(`/categories`)} 
              >
                <img src={urlPath === '/categories' ? `${BaseImgUrl}/icons/footer-categories.svg` : `${BaseImgUrl}/icons/footer-categories.svg`} className='offCanvas-img ms-2 me-3' style={{ height: '25px' }} alt="dashboard" />
                <h5 className='mb-0'>Categories</h5>
              </div>

              {/* About */}
              <div 
                className={`${urlPath === '/about' && `rounded-2 bg-warning`} p-2 mb-3 d-flex align-items-center text-decoration-none text-white`}
                onClick={() => handleClickOffCanvas(`/about`)} 
              >    
                <img src={urlPath === '/about' ? `${BaseImgUrl}/icons/footer-about.svg` : `${BaseImgUrl}/icons/footer-about.svg`} className='offCanvas-img ms-2 me-3' style={{ height: '25px' }} alt="dashboard" />
                <h5 className='mb-0'>About</h5>
              </div>

              {/* Contact */}
              <div 
                className={`${urlPath === '/contact' && `rounded-2 bg-warning`} p-2 mb-5 d-flex align-items-center text-decoration-none text-white`}
                onClick={() => handleClickOffCanvas(`/contact`)} 
              >
                <img src={urlPath === '/contact' ? `${BaseImgUrl}/icons/footer-contact.svg` : `${BaseImgUrl}/icons/footer-contact.svg`} className='offCanvas-img ms-2 me-3' style={{ height: '25px' }} alt="dashboard" />
                <h5 className='mb-0'>Contact</h5>
              </div>

              {/* ----- Contact Us ----- */}
              <div className='text-white px-4 mb-'>
                <div className='mb-3 d-inline-block' onClick={() => window.open(`https://www.google.com/maps/place/Raipur,+Chhattisgarh/@21.2366007,81.6337063,66m/data=!3m1!1e3!4m6!3m5!1s0x3a28e7d04df5047f:0xbd656d629fea9226!8m2!3d21.2454901!4d81.641665!16s%2Fg%2F11rq2clr5v?entry=ttu`, '_blank')}>
                  <img src={`${BaseImgUrl}/icons/footer-location.svg`} className='me-3' style={{ height: '17px' }} alt="icons" />
                  <span className='text-white-50'>{navkar.address}</span>
                </div>

                <a href={`tel:${navkar.number}`} className='text-white text-decoration-none d-inline-block mb-3'>
                  <img src={`${BaseImgUrl}/icons/footer-call.svg`} className='me-3' style={{ height: '15px' }} alt="mail" />
                  <span>+91 {navkar.number}</span>
                </a>

                <a href={`mailto:${navkar.email}`} className='text-white text-decoration-none d-inline-block' >
                  <img src={`${BaseImgUrl}/icons/footer-mail.svg`} className='me-3' style={{ height: '15px' }} alt="mail" />
                  <span className='text-white-50'>{navkar.email}</span>
                </a>
              </div>

              {/* ----- Social Media ----- */}
              <div className='mx-4 mt-5 d-flex justify-content-between'>
                <img src={`${BaseImgUrl}/icons/footer-instagram.svg`} onClick={() => window.open(`${navkar.instagram}`, '_blank')} style={{ width: '25px' }} alt="instagram" />
                <img src={`${BaseImgUrl}/icons/footer-facebook.svg`} onClick={() => window.open(`${navkar.facebook}`, '_blank')} style={{ width: '13px' }} alt="facebook" />
                <img src={`${BaseImgUrl}/icons/footer-whatsapp.svg`} onClick={() => window.open(`https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`, '_blank')} style={{ width: '23px' }} alt="whatsapp" />
                <img src={`${BaseImgUrl}/icons/footer-youtube.svg`} onClick={() => window.open(`${navkar.youtube}`, '_blank')} style={{ width: '30px' }} alt="youtube" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Tablet, Laptop Header */}
      <section className={`header ${isScrolled && `header-blur shadow-sm`} fixed-top d-none d-md-flex align-items-center py-3`}>
        <img src={`${BaseImgUrl}/logo.png`} className='header-logo ms-md-4 ms-lg-5' onClick={() => navigate(`/`)} alt="logo" />

        <div className='d-flex mx-auto'>
          <Link to={`/`} className='text-decoration-none text-white me-md-3 me-lg-5 fs-5 fw-semibold'>Home</Link>
          <Link to={`categories`} className='text-decoration-none text-white mx-md-4 mx-lg-5 fs-5 fw-semibold'>Categories</Link>
          <Link to={`about`} className='text-decoration-none text-white me-md-4 me-lg-5 ms-md-3 ms-lg-5 fs-5 fw-semibold'>About</Link>
          <Link to={`contact`} className='text-decoration-none text-white ms-md-3 ms-lg-5 fs-5 fw-semibold'>Contact</Link>
        </div>

        <img src={`${BaseImgUrl}/icons/search-white.svg`} className='me-4' style={{ height: '20px' }} alt="search" />

        <div className='d-flex flex-column mx-md-4 mx-lg-5'>
          <a href={`mailto:${navkar.email}`} className='text-decoration-none text-white fw-semibold'>{navkar.email}</a>
          <a href={`tel:${navkar.number}`} className='text-decoration-none text-white fw-semibold'>+91 {navkar.number}</a>
        </div>
      </section>
    </>
  );
};

export default Header;