import React, { useContext } from 'react';
import { BaseImgUrl } from '../../Config.js'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UseContext/NavkarContext.js';
 


const Footer = () => {

  const navigate = useNavigate();

  const { navkar } = useContext(UserContext); 

  return (
    <>
      <section>
        {/* Footer 1 */}
        <section className='bg-dark row row-cols-1 row-cols-md-4 mx-auto px-5 py-5 ps-md-0'>
          {/* Logo */}
          <div className='p-0 mb-5 mb-md-0 d-flex justify-content-center align-items-center align-items-md-start' onClick={() => navigate(`/`)}>
            <img src={`${BaseImgUrl}/logo.png`} className='footer-logo' alt="logo" />
          </div>

          {/* Information */}
          <div className='text-white p-0 mb-5 mb-md-0'>
            <h4 className='fw-normal' style={{ color: '#FFA700' }}>Information</h4>

            <hr className='hr w-75' style={{ color: '#FFA700' }} />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => navigate(`/`)}>
              <img src={`${BaseImgUrl}/icons/footer-home.svg`} className='me-3' style={{ height: '17px', width: '23px' }} alt="icons" />
              <span className='text-white-50'>Home</span>
            </div> 

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => navigate(`/categories`)}>
              <img src={`${BaseImgUrl}/icons/footer-categories.svg`} className='me-3' style={{ height: '17px', width: '23px' }} alt="icons" />
              <span className='text-white-50'>Cateories</span>
            </div> 

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => navigate(`/about`)}>
              <img src={`${BaseImgUrl}/icons/footer-about.svg`} className='me-3' style={{ height: '17px', width: '23px' }} alt="icons" />
              <span className='text-white-50'>About</span>
            </div> 

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => navigate(`/contact`)}>
              <img src={`${BaseImgUrl}/icons/footer-contact.svg`} className='me-3' style={{ height: '17px', width: '23px' }} alt="icons" />
              <span className='text-white-50'>Contact</span>
            </div> 

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => navigate(`/termsAndCondition`)}>
              <img src={`${BaseImgUrl}/icons/footer-tac.svg`} className='me-3' style={{ height: '17px', width: '23px' }} alt="icons" />
              <span className='text-white-50'>Terms & Condition</span>
            </div>
          </div>

          {/* Social Media */}
          <div className='text-white p-0 mb-5 mb-md-0'>
            <h4 className='fw-normal' style={{ color: '#FFA700' }}>Social Media</h4>

            <hr className='hr w-75' style={{ color: '#FFA700' }} />
            
            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => window.open(`${navkar.instagram}`, '_blank')}>
              <img src={`${BaseImgUrl}/icons/footer-instagram.svg`} className='me-3' style={{ height: '17px', width: '30px' }} alt="icons" />
              <span className='text-white-50'>Instagram</span>
            </div>

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => window.open(`${navkar.facebook}`, '_blank')}>
              <img src={`${BaseImgUrl}/icons/footer-facebook.svg`} className='me-3' style={{ height: '17px', width: '30px' }} alt="icons" />
              <span className='text-white-50'>Facebook</span>
            </div>

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => window.open(`https://wa.me/91${navkar.number}?text=Hello%20I%20would%20like%20to%20place%20an%20order`, '_blank')}>
              <img src={`${BaseImgUrl}/icons/footer-whatsapp.svg`} className='me-3' style={{ height: '17px', width: '30px' }} alt="icons" />
              <span className='text-white-50'>Whatsapp</span>
            </div>

            <br />

            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => window.open(`${navkar.youtube}`, '_blank')}>
              <img src={`${BaseImgUrl}/icons/footer-youtube.svg`} className='me-3' style={{ height: '17px', width: '30px' }} alt="icons" />
              <span className='text-white-50'>Youtube</span>
            </div>
          </div>

          {/* Contact Us */}
          <div className='text-white p-0'>
            <h4 className='fw-normal' style={{ color: '#FFA700' }}>Contact Us</h4>

            <hr className='hr w-75' style={{ color: '#FFA700' }} />
    
            <div className='mb-3 cursor-pointer d-inline-block' onClick={() => window.open(`https://www.google.com/maps/place/Raipur,+Chhattisgarh/@21.2366007,81.6337063,66m/data=!3m1!1e3!4m6!3m5!1s0x3a28e7d04df5047f:0xbd656d629fea9226!8m2!3d21.2454901!4d81.641665!16s%2Fg%2F11rq2clr5v?entry=ttu`, '_blank')}>
              <img src={`${BaseImgUrl}/icons/footer-location.svg`} className='me-3' style={{ height: '17px' }} alt="icons" />
              <span className='text-white-50'>{navkar.address}</span>
            </div>

            <a href={`tel:${navkar.number}`} className='mb-3 text-white text-decoration-none d-inline-block'>
              <img src={`${BaseImgUrl}/icons/footer-call.svg`} className='me-3' style={{ height: '17px' }} alt="icons" />
              <span className='fw-bold'>+91 {navkar.number}</span>
            </a>

            <br />

            <a href={`mailto:${navkar.email}`} className='mb-3 text-decoration-none'>
              <img src={`${BaseImgUrl}/icons/footer-mail.svg`} className='me-3' style={{ height: '15px' }} alt="icons" />
              <span className='text-white-50'>{navkar.email}</span>
            </a>
          </div>
        </section> 

        {/* Footer 2 */}
        <section className='text-center py-2 py-md-3' style={{ backgroundColor: '#FFA700' }}>
          <span className='footer-2-text fw-semibold text-dark'>2024 @ All Rights Reserved Powered By www.navkarnaturally.in</span>
        </section>
      </section>
    </>
  )
}

export default Footer
