import React from 'react';
import { BaseImgUrl } from '../../Config.js'



const About = () => {

  return (
    <>
      <section className=''>
        {/* Banner */}
        <div className='about-banner position-relative'>
          <img src={`${BaseImgUrl}/about-banner.png`} className='about-banner-img h-100 w-100 object-fit-cover' alt="about banner" />
          <img src={`${BaseImgUrl}/icons/bottom-arrow.svg`} className='bg-white p-2 rounded-circle position-absolute top-100 start-50 translate-middle shadow' style={{ height: '50px' }} alt="bottom-aroow" />
        </div>

        {/* Our Story */}
        <div className='our-story d-md-flex mx-auto'>
          <div className='our-story-img d-md-flex align-items-md-start'>
            <img src={`${BaseImgUrl}/our-story-1.png`} className='our-story-img-1 shadow-sm rounded-4' alt="our-story-1" />
            <img src={`${BaseImgUrl}/our-story-2.png`} className='our-story-img-2 shadow-sm rounded-4' alt="our-story-2" />
          </div>

          <div className='our-story-content'>
            <h1 className='text-success'>Our Story</h1>

            <p className=''>Lorem ipsum dolor sit, reiciendis dolorem nisi nostrum? Aspernatur explicabo, recusandae fuga dicta illum autem numquam in repellat nesciunt vitae iste quibusdam aliquid reiciendis possimus minus aperiam impedit consequatur labore consequuntur. Corrupti veniam eveniet eius debitis nulla blanditiis aspernatur saepe quod vel sit, cumque totam cupiditate fugit dignissimos culpa commodi recusandae nisi iusto architecto quae beatae, error soluta labore laboriosam! Perferendis.</p>
          </div>
        </div>

        {/* Our mission */}
        <div className='our-mission d-md-flex flex-md-row-reverse mx-auto'>
          <div className='our-mission-img py-3'>
            <img src={`${BaseImgUrl}/our-mission-1.png`} className='our-mission-img-1 shadow-sm rounded-4' alt="our-mission-1" />
          </div>

          <div div className='our-mission-content'>
            <h1 className='text-success'>Our mission</h1>

            <p className='mb-3'>Lorem ipsum dolor sit, reiciendis dolorem nisi nostrum? Aspernatur explicabo, recusandae fuga dicta illum autem numquam in repellat nesciunt vitae iste quibusdam aliquid reiciendis possimus minus aperiam impedit consequatur labore consequuntur. Corrupti veniam eveniet eius debitis nulla blanditiis aspernatur saepe quod vel sit, cumque totam cupiditate fugit dignissimos culpa commodi recusandae nisi iusto architecto quae beatae, error soluta labore laboriosam! Perferendis.</p>

            <img src={`${BaseImgUrl}/our-mission-2.png`} className='our-mission-img-2 me-3' alt="our-mission-2" />
            <img src={`${BaseImgUrl}/our-mission-3.png`} className='our-mission-img-3' alt="our-mission-3" />
          </div>
        </div>

        {/* Our Story */}
        <div className='our-story d-md-flex mx-auto'>
          <div className='our-story-img d-md-flex align-items-md-start'>
            <img src={`${BaseImgUrl}/our-story-1.png`} className='our-story-img-1 shadow-sm rounded-4' alt="our-story-1" />
            <img src={`${BaseImgUrl}/our-story-2.png`} className='our-story-img-2 shadow-sm rounded-4' alt="our-story-2" />
          </div>

          <div className='our-story-content'>
            <h1 className='text-success'>Our Story</h1>

            <p className=''>Lorem ipsum dolor sit, reiciendis dolorem nisi nostrum? Aspernatur explicabo, recusandae fuga dicta illum autem numquam in repellat nesciunt vitae iste quibusdam aliquid reiciendis possimus minus aperiam impedit consequatur labore consequuntur. Corrupti veniam eveniet eius debitis nulla blanditiis aspernatur saepe quod vel sit, cumque totam cupiditate fugit dignissimos culpa commodi recusandae nisi iusto architecto quae beatae, error soluta labore laboriosam! Perferendis.</p>
          </div>
        </div>

        {/* Our mission */}
        <div className='our-mission d-md-flex flex-md-row-reverse mx-auto pb-5'>
          <div className='our-mission-img py-3'>
            <img src={`${BaseImgUrl}/our-mission-1.png`} className='our-mission-img-1 shadow-sm rounded-4' alt="our-mission-1" />
          </div>

          <div div className='our-mission-content'>
            <h1 className='text-success'>Our mission</h1>

            <p className='mb-3'>Lorem ipsum dolor sit, reiciendis dolorem nisi nostrum? Aspernatur explicabo, recusandae fuga dicta illum autem numquam in repellat nesciunt vitae iste quibusdam aliquid reiciendis possimus minus aperiam impedit consequatur labore consequuntur. Corrupti veniam eveniet eius debitis nulla blanditiis aspernatur saepe quod vel sit, cumque totam cupiditate fugit dignissimos culpa commodi recusandae nisi iusto architecto quae beatae, error soluta labore laboriosam! Perferendis.</p>

            <img src={`${BaseImgUrl}/our-mission-2.png`} className='our-mission-img-2 me-3' alt="our-mission-2" />
            <img src={`${BaseImgUrl}/our-mission-3.png`} className='our-mission-img-3' alt="our-mission-3" />
          </div>
        </div>

        {/* Value */}
        <div className='value mt-3 my-5 d-lg-flex align-items-start justify-content-start'>
          <img src={`${BaseImgUrl}/value-1.png`} className='value-img-1 me-3 me-sm-4 me-lg-5' alt="value-1" />

          <img src={`${BaseImgUrl}/value-2.png`} className='value-img-2 me-xl-3 me-xxl-5' alt="value-2" />

          <div className='value-content mt-4 mt-sm-5 mt-lg-0 d-flex flex-column justify-content-between px-4 px-sm-5 px-lg-0'>
            <div className='text-success fs-1'>Value</div>

            <div>
              <li className='ul-dot-green fw-bold mt-3 mt-lg-0 mb-xl-2 fs-5'>Quality-driven</li>
              <p style={{ marginLeft: '30px' }}>Deliver products of the highest quality to our consumers every time</p>
            </div>

            <div>
              <li className='ul-dot-green fw-bold mt-3 mt-lg-0 mb-xl-2 fs-5'>Customer-centric</li>
              <p style={{ marginLeft: '30px' }}>Understand the sensitivities of internal as well as external customers</p>
            </div>

            <div>
              <li className='ul-dot-green fw-bold mt-3 mt-lg-0 mb-xl-2 fs-5'>MiLeadershiplk</li>
              <p style={{ marginLeft: '30px' }}>Display the courage to shape a better future</p>
            </div>

            <div>
              <li className='ul-dot-green fw-bold mt-3 mt-lg-0 mb-xl-2 fs-5'>Responsive</li>
              <p style={{ marginLeft: '30px' }}>Actively respond to changing market dynamics</p>
            </div>
          </div>
        </div>

        {/* Customers Reactions */}
        <div className='border border-danger my-5'>
          <h1 className='text-center'>Customers Reactions</h1>

          <div className='border border-primary'>
          </div>
        </div>
      </section>


      {/* Avi ka code hai  */}
      {/* <section>
        <div className='about_banner'>
          <h1 className='text-warning'>NAVKAR</h1>
          <p>Naturally Home Made</p>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>

        <div className='py-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='container flex-col'>
                  <div className='text-start'>
                    <h1 className='text-start'>Our Story</h1>
                  </div>
                  <p className='w-50'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum accusamus architecto fugiat! Facilis iusto eaque ullam minus, saepe, eos eum quam quidem blanditiis alias veritatis doloribus reprehenderit? Sit incidunt aspernatur non ducimus natus dolore architecto fugit quod rerum eos minus repellat ratione optio molestiae culpa provident quasi, odio consectetur soluta.</p>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className="container">
                  <img width="50%" src="/images/aachar.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className="container">
                  <img width="50%" src="/images/aachar.png" alt="" />
                </div>
              </div>

              <div className='col-12 col-lg-6'>
                <div className='container flex-col'>
                  <div className='text-start'>
                    <h1 className='text-start'>Our Mission</h1>
                  </div>
                  <p className='w-50'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum accusamus architecto fugiat! Facilis iusto eaque ullam minus, saepe, eos eum quam quidem blanditiis alias veritatis doloribus reprehenderit? Sit incidunt aspernatur non ducimus natus dolore architecto fugit quod rerum eos minus repellat ratione optio molestiae culpa provident quasi, odio consectetur soluta.</p>
                </div>
              </div>

            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className='container flex-col'>
                  <div className='text-start'>
                    <h1 className='text-start'>Our Vision</h1>
                  </div>
                  <p className='w-50'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum accusamus architecto fugiat! Facilis iusto eaque ullam minus, saepe, eos eum quam quidem blanditiis alias veritatis doloribus reprehenderit? Sit incidunt aspernatur non ducimus natus dolore architecto fugit quod rerum eos minus repellat ratione optio molestiae culpa provident quasi, odio consectetur soluta.</p>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className="container">
                  <img width="50%" src="/images/aachar.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className='container'>
            <div className='row'>
              <div className='col-12 col-lg-6'>
                <img width="50%" src="/images/aachar.png" alt="" />
              </div>

              <div className='col-12 col-lg-6'>
                <div className='container flex-col'>
                  <div className='text-start'>
                    <h1 className='text-start'>Values</h1>
                  </div>
                  <ul>

                    <div>
                      <li>Quality-driven</li>
                      <small>Deliver products of the highest quality to our consumers every time</small>
                    </div>

                    <div>
                      <li>Customer-centric</li>
                      <small>Understand the sensitivities of internal as well as external customers</small>
                    </div>

                    <div>
                      <li>Leadership</li>
                      <small>Display the courage to shape a better future</small>
                    </div>

                    <div>
                      <li>Responsive</li>
                      <small>Actively respond to changing market dynamics</small>
                    </div>

                  </ul>
                </div>

              </div>

              <h1 className='text-center'>Customer Reviews</h1>


            </div>
          </div>
        </div>

      </section> */}
    </>
  )
}

export default About
