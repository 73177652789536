import React from 'react';
import { BaseImgUrl } from '../../Config';

const Certificates = () => {
  return (
    <section className='my-5 container px-0'>
      <div className='my-3'>
        <div className='d-flex flex-column align-items-center'>
          <h3 className='home-head-1 mb-0'>Certificates</h3>
          <small className='home-head-2 my-2 text-secondary w-75 text-center'>Explore Interesting Food-Facts That Will Change The Way You Eat</small>
          <img src={`${BaseImgUrl}/icons/stick.svg`} className='home-stick' alt="stick" />
        </div>
      </div>

      <div className='row mx-3'>
        {Array(4).fill(0).map((e, i) => (
          <img key={i} className='col-3 px-0' src={`/images/certificates/logo${i + 1}.png`} alt="ogo" />
        ))}
      </div>
    </section>
  )
}

export default Certificates
